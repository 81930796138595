import React from "react";
import Helmet from "react-helmet";
import Layout from "layout";
import SEO from "components/SEO/SEO";
import Landing from "components/Landing/Landing";
import Footer from "components/Footer";
import Header from "components/Header";
import config from "../../data/SiteConfig";

class Index extends React.Component {
  render() {
    return (
      <div className="index-container">
        <Helmet title={config.siteTitle} />
        <SEO />
        <Header />
        <Landing />
        <Footer />
      </div>
    );
  }
}

export default Index;
