import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import styled from "styled-components";

import config from "../../../data/SiteConfig";

const Divv = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
`;

const Desc = styled.h2`
  width: 100%;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 3px #000;
`;

const Name = styled(Desc)`
  font-size: 3em;
`;

const Wrapper = styled.div`
  overflow: hidden;
  & ~ footer {
    position: fixed;
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "background.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1080, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Image
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: -1
          }}
          fluid={data.file.childImageSharp.fluid}
        />
        <Divv>
          <Name>{config.siteTitleAlt}</Name>
          <Desc>{config.siteDescription}</Desc>
        </Divv>
      </Wrapper>
    )}
  />
);
